.Home {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 100%;
  /* padding: var(--default-padding); */
  box-sizing: border-box;
  background-image: linear-gradient(#ffffff9b, #ffffff9b), url('../../../public/images/cloud-image.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
}

.home-title{
  margin-top: 20px;
  font-family: 'NVRFNSHD-DisplayItalic';
}

.home-news{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  padding: 0 50px;
  width: 50%;
  box-sizing: border-box;
  max-width: 1000px;
  margin-bottom: auto;
  gap: calc(var(--default-padding) * 1);

  div{
    justify-content: center;
  }
}

.toggle-btn-container, .add-content{
  display: flex;
  flex-direction: row;
}

.toggle-btn-container{
  width: 100%;
  /* max-width: 1500px; */
  align-items: center;
  gap: 5px;
  /* margin-top: 10px;
  margin-left: 20px; */
}

.add-content {
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding: var(--default-padding) 0;
}

.add-content {
  /* max-width: 1500px; */
  width: 30%;
  justify-content: center;
}

.add-btn {
  margin-bottom: var(--default-padding);
  width: 100% !important;
  justify-content: center;
  padding: 15px;
  border-radius: 50px;
}

@media screen and (max-width: 700px) {
  .home-news{
    flex-direction: column;
    padding: 0;
    width: 100%;
    max-width: unset;
    margin-top: var(--default-padding);
  }

  .add-content {
    width: 100%;
  }
  
}

/* @media screen and (min-width: 1500px) {
  .home-news{
    max-width: 1300px;
  }
} */