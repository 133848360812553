@font-face {
  font-family: "NVRFNSHD-Display";
  src: url("../public/fonts/NVRFNSHD-Display.otf");
}
@font-face {
  font-family: "NVRFNSHD-DisplayItalic";
  src: url("../public/fonts/NVRFNSHD-DisplayItalic.otf");
}
@font-face {
  font-family: "NVRFNSHD-Italic";
  src: url("../public/fonts/NVRFNSHD-Italic.otf");
}
@font-face {
  font-family: "NVRFNSHD-Regular";
  src: url("../public/fonts/NVRFNSHD-Regular.otf");
}
@font-face {
  font-family: "GothamMedium";
  src: url("../public/fonts/GothamMedium.otf");
}
@font-face {
  font-family: "CodeNext-Black";
  src: url("../public/fonts/CodeNext-Black.otf");
}
@font-face {
  font-family: "CodeNext-Bold";
  src: url("../public/fonts/CodeNext-Bold.otf");
}
@font-face {
  font-family: "CodeNext-Book";
  src: url("../public/fonts/CodeNext-Book.otf");
}
@font-face {
  font-family: "CodeNext-Light";
  src: url("../public/fonts/CodeNext-Light.otf");
}

/* html{
  width: 100%;
  height: 100%;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100%;
  height: 100%; */

  --default-padding: 15px;
  --button-text-size: 16px;
}

.close {
  color: red;
}
.done {
  color: green;
}
.favorite {
  color: #000000;
}

/* For Webkit browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #5c5c5c;
}

/* For Microsoft Edge and IE */
::-ms-scrollbar {
  width: 8px;
}

::-ms-scrollbar-track {
  background-color: #000;
}

::-ms-scrollbar-thumb {
  background-color: #5c5c5c;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
p {
  margin: calc(var(--default-padding) / 2) 0;
}

h1 {
  font-family: "CodeNext-Bold";
  /* text-transform: uppercase; */
  font-weight: 100;
  font-size: 19px;
}

h2 {
  font-family: "CodeNext-Book";
  font-size: 34px;
}

h3 {
  font-family: "CodeNext-Bold";
  font-weight: 200;
  font-size: 24px;
}

h4 {
  font-family: "CodeNext-Bold";
  font-weight: 500;
  font-size: 22px;
}

h5 {
  font-family: "Gotham", sans-serif;
  font-weight: 400;
  font-size: 18px;
}

p, .text_list {
  font-family: "CodeNext-Book";
  font-weight: 300;
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  h1 {
    font-family: "CodeNext-Bold";
    /* text-transform: uppercase; */
    font-weight: 100;
    font-size: 19px;
  }

  h2 {
    font-family: "NVRFNSHD-Display";
    font-weight: 300;
    font-size: 36px;
  }

  h3 {
    font-family: "CodeNext-Bold";
    font-weight: 200;
    font-size: 24px;
  }

  h4 {
    font-family: "CodeNext-Bold";
    font-weight: 500;
    font-size: 22px;
  }

  p {
    font-family: "CodeNext-Book";
    font-weight: 300;
    font-size: 18px;
  }
}
